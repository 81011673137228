import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "amfe-flexible/index.js";

import "./assets/css/index.css";

import { Posting } from "./utils/api";
Vue.prototype.$request = Posting;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
