<template>
  <div class="course">
    <van-sticky>
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="value1" :options="option1" />
        <van-dropdown-item v-model="value2" :options="option2" />
      </van-dropdown-menu>
    </van-sticky>

    <template>
      <div class="news">
        <div
          class="list flex"
          v-for="i in courseList"
          :key="i.id"
          @click="navigateToPage(i.id)"
        >
          <div class="ims">
            <van-image
              fit="cover"
              :src="i.imgSrc"
              class="s"
              mode="aspectFill"
            />
            <div class="play">免费</div>
          </div>

          <div class="tit">
            <li>{{ i.info }}</li>
            <p v-html="i.introduce"></p>
            <span> {{ i.view }}人已学习 </span>
          </div>
        </div>
      </div>

      <van-empty image="error" description="暂无数据" />
    </template>
  </div>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Sticky,
  Empty,
  Toast,
  Dialog,
  Image as VanImage,
} from "vant";
export default {
  data() {
    return {
      value1: 0,
      value2: "a",
      option1: [{ text: "全部视频", value: 0 }],
      option2: [
        { text: "默认排序", value: "a" },
        { text: "观看排序", value: "b" },
      ],

      courseList: [],
    };
  },
  components: {
    [DropdownMenu.name]: DropdownMenu, // 下拉框组
    [DropdownItem.name]: DropdownItem, // 下拉框
    [Sticky.name]: Sticky, // 吸顶容器
    [Empty.name]: Empty, // 无数据
    [Toast.name]: Toast, // 文字提示
    [Dialog.name]: Dialog, // 确认按钮提示
    [VanImage.name]: VanImage, // 图片
  },
  created() {
    this.post({ pages: 1 });
  },
  mounted() {},
  computed: {},
  methods: {
    post(e) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$request(0, e)
        .then((res) => {
          this.courseList = res;
        })
        .catch((message) => {
          Dialog.alert({
            message,
            confirmButtonColor: "#1989fa",
          }).then(() => {});
        })
        .finally(() => {
          Toast.clear();
        });
    },

    navigateToPage(e) {
      this.$router.push(`/about?id=${e}`);
    },

    // 选择
    sortsChang(e) {
      this.postList({ pages: 1, sorts_id: e });
      this.subscript(e);
      this.set = e;
    },

    // 动态底部
    subscript(e) {
      this.left = `left:${this.arr[e].left}px;width:${this.arr[e].width}px;`;
      this.scrollLeft = this.arr[e].left - 50;
    },
  },
};
</script>

<style lang="scss" scoped>
.course {
  .content {
    padding: 0 20px;
    margin-bottom: 10px;
  }

  .news {
    padding-top: 10px;
    background: #fff;
    .list {
      padding: 10px 20px;
      margin-bottom: 6px;
      // height: 220px;
      .ims {
        width: 340px;
        position: relative;
        .s {
          width: 340px;
          height: 220px;
          display: block;
          border-radius: 4px;
        }
        .play {
          position: absolute;
          right: 10px;
          top: 0;
          background: rgba(211, 84, 0, 0.9);
          padding: 6px 15px;
          color: #fff;
          font-size: 20px;
          border-radius: 0 0 6px 6px;
          text {
            display: inline-block;
            vertical-align: middle;
            margin-top: -6px;
            margin-right: 4px;
          }
        }
      }

      .tit {
        width: 350px;
        // height: 220px;
        text-align: left;
        li {
          font-size: 28px;
          color: #000;
          margin-bottom: 6px;
          height: 80px;
        }
        p {
          font-size: 26px;
          color: #666;
          margin-block-start: 0;
          margin-block-end: 0;
          line-height: 32px;
          height: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        span {
          font-size: 24px;
          color: #999;
        }
      }
    }
  }
}
</style>
