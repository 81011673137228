import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue"; // 列表
import Examine from "../views/examine.vue"; // 审核专用

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/examine",
    name: "Home",
    component: Examine,
    meta: { title: "温馨提示" },
  },
  {
    path: "/curriculum",
    name: "Curriculum",
    // component: Examine,
    // meta: { title: "温馨提示" },
    component: () => import("../views/Curriculum.vue"),
    meta: { title: "课程" },
  },
  {
    path: "/review",
    name: "Review",
    // component: Examine,
    // meta: { title: "温馨提示" },
    component: () => import("../views/review.vue"),
    meta: { title: "活动回顾" },
  },
  {
    path: "/404",
    component: () => import("../views/404.vue"),
    meta: { title: "404" },
  },
  {
    path: "/info",
    component: () => import("../views/info.vue"),
    meta: { title: "关于我们" },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes,
  });

const router = createRouter();

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
