// 封装请求：get post
// 面向对象es6 promise  等等。。。

// 请求地址
import axios from "axios";

import { url } from "./request.js";

// POST请求
let Posting = function (urling, shopdata) {
  // HmacSHA1 加密
  // var CryptoJS = require("crypto-js");
  // let token = localStorage.getItem("token") | '';
  // let HmacSHA1 = CryptoJS.HmacSHA1(token, "90d2fca50ea8ed5472c5776c9fc53638").toString();
  return new Promise((resolve, reject) => {
    axios
      .post(url[urling][0], shopdata, {
        headers: {
          Authorization: localStorage.getItem("token")
            ? localStorage.getItem("token")
            : "123",
        },
      })
      .then(function (response) {
        let { status, msg, data } = response.data;
        if (status == 0) {
          resolve(data);
        } else {
          reject(msg);
        }
      })
      .catch(function (e) {
        reject(e);
      })
      .finally(() => {});
  });
};

export { Posting };
