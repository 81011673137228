<template>
  <div class="exa">
    <div style="width: 100%">
      <van-cell-group inset>
        <van-cell
          title="温馨提示"
          value=""
          label="因小程序限制，请前往腾讯视频搜索：雅和知识中心进行观看。对此造成的不便深感歉意"
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup } from "vant";
export default {
  components: {
    [Cell.name]: Cell, // 单元格组
    [CellGroup.name]: CellGroup, // 单元格
  },
};
</script>

<style lang="scss" scoped>
.exa {
  height: 100vh;
  padding-top: 50px;
  background: #f9f9f9;
}
::v-deep .van-cell__title,
::v-deep .van-cell__value {
  flex: auto;
}
</style>
