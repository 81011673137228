// 公用地址
// let web = 'http://101.34.128.130:8800/gold/portal';
let web = "/v1/";

// 接口
let url = [
  [`${web}course/index`, "0 获取课程列表"],
  [`${web}video/index`, "1 获取课程视频详情"],
  [`${web}video/view`, "2 推动一个播放量"],
  [`${web}like/add`, "3 点赞收藏"],
  [`${web}course/show`, "4 获取课程详情"],

  
];

// 抛出
export { web, url };
